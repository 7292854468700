import { Box, IconButton, makeStyles } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import _ from "lodash";
import { useEffect, useState } from "react";
import { BrowserView, MobileView, isMobileOnly } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import GenericAlert from "../../Components/GenericAlert";
import { GenericLoader } from "../../Components/GenericLoader";
import Constants from "../../Constants/constants";
import { ComplianceTypes, ImportStatus, ProcessStatus } from "../../Constants/enums";
import useAPIRequest from "../../Service/useAPIRequest";
import IComplianceContent from "../../interfaces/IComplianceContent";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IDocumentDefinition from "../../interfaces/IDocumentDefinition";
import IFeedbackRating from "../../interfaces/IFeedbackRating";
import IImportableDigitalData from "../../interfaces/IImportableDigitalData";
import IProfile from "../../interfaces/IProfile";
import IValidationFailure from "../../interfaces/IValidationFailure";
import InfoTypes from "../../interfaces/InfoTypes";
import LocalStorageService from "../../utils/localStorageService";
import { GetComplianceTypeUIContent, GetCountriesData, GetNationalitiesData, GetTitlesData } from "../../utils/referenceDataService";
import utilFunctions from "../../utils/util";
import AddressHistory from "../AddressHistory";
import CheckIntro from "../CheckIntro";
import Convictions from "../Convictions";
import PersonalInformation from "../PersonalInfo";
import DBSTermsAndConditions from "../TermsAndConditions/DBS_TNC";
import VerifyIdentity from "../VerifyIdentity";
import WorkedAbroad from "../WorkedAbroad";
import ImportDigitalIdentityData from "../Yoti/Import/ImportDigitalIdentityData";
import "./index.scss";

const useStyles = makeStyles(() => ({
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    position: "sticky",
    top: "50vh",
    height: "100px"
  },
}));

type DBSComplianceParams = {
  checkId: string
}

type DBSType = 'basicdbs' | 'dbs'

export default function DBSCompliance() {
  
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const dbsType = pathname.split('/')[1] as DBSType;
  const { checkId } = useParams<DBSComplianceParams>();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [feedback, setFeedback] = useState<IFeedbackRating>();
  const dbsUIContent: IComplianceContent = (dbsType === 'basicdbs') ?  GetComplianceTypeUIContent(ComplianceTypes.BasicDBS) : GetComplianceTypeUIContent(ComplianceTypes.DBS);
  const [complianceTypeSubmitData, setComplianceTypeSubmitData] = useState<IComplianceProcess>();
  const [isSubmissionComplete, setSubmissionComplete] = useState(false);
  const [isSubmissionError, setSubmissionError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [callRefresh, setCallRefresh] = useState(false);
  const [complianceProcessData, setComplianceProcessData] = useState<IComplianceProcess>();
  const [loader, setLoader] = useState(false);
  const [isCheckEditable, setIsCheckEditable] = useState(true);
  const [confirmImportModalOpen, setConfirmImportModalOpen] = useState(false);



  var digitalIDImportableData: IImportableDigitalData = {};
  
  useEffect(() => {
    setCallRefresh(true);
    setLoader(true);
  }, [])

  useEffect(() => {
    if (complianceProcessData) {
      setIsCheckEditable(complianceProcessData!.status === ProcessStatus.WaitingForWorker);
      if ((complianceProcessData.digitalIdentity!) && (complianceProcessData.digitalIdentity.isComplete) && (complianceProcessData.digitalIdentity.importStatus === ImportStatus.Pending)) {
        setConfirmImportModalOpen(true);
      }
    }
  }, [complianceProcessData])

  if (dbsUIContent) {
    if (dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.INTRO}) === -1) {
  
      dbsUIContent.steps.splice(0, 0, {
        title: dbsUIContent.intro.title,
        content: dbsUIContent.intro.content,
        detail: dbsUIContent.intro.detail,
        editor: Constants.COMPLIANCE_STEPS_EDITORS.INTRO
      })
    }
  
    if (dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.OUTRO}) === -1) {
  
      dbsUIContent.steps.push({
        title: dbsUIContent.outro.title,
        content: dbsUIContent.outro.content,
        detail: dbsUIContent.outro.detail,
        editor: Constants.COMPLIANCE_STEPS_EDITORS.OUTRO
      })
    }
  }

  GetCountriesData();
  GetNationalitiesData();
  GetTitlesData();

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmitRequest = (feedback: IFeedbackRating, complianceData: IComplianceProcess) => {
    setFeedback(feedback);
    history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
    //Submit Compliance Process Data for Compliance Type
    //if (complianceData) {
     // setComplianceTypeSubmitData(complianceData);
    //}
    
  }

  const SubmitDBSComplianceData = async (complianceData: IComplianceProcess) => {
    var cleansedRequestObj = utilFunctions.cleanseComplianceTypeSubmitRequestObject(complianceData);
    useAPIRequest(Constants.API_URLS.POST_WORKER_COMPLETE_DBS_DATA_SUFFIX + complianceData.id + Constants.API_URLS.POST_WORKER_COMPLETE_DATA, {
      method: "POST",
      body: cleansedRequestObj
    }).then((res) => {
        if (res && res !== undefined) {
          if (res.ok) {
            setSubmissionError(false);
            setSubmissionComplete(true);
          } else {
            //check for Validations first 
            const errors: IValidationFailure[] = res;
            handleSubmitErrors(errors);
          }
        }
    }).catch(() => {
      handleSubmitErrors();
    });
  }

  const handleSubmitErrors = (errors?: IValidationFailure[]) => {
    if (errors) {
      let errorMsg: string = t("errors.save_error_msg_dynamic") + "<ul>";
      _.map(errors, (err) => {
        errorMsg = errorMsg.concat("<li>", err.errorMessage, "</li>");
      });
      errorMsg.concat("</ul>");
      setSubmissionError(true);
      setSubmissionComplete(true);
      setErrorMessage(errorMsg);
    } else {
      setErrorMessage(t("errors.save_error_msg"));
      setShowError(true);
    }
  }

  useEffect(() => {
    async function SubmitComplianceTypeDataRequest() {
      if (complianceTypeSubmitData) {
        switch (complianceTypeSubmitData.complianceType.toLocaleLowerCase()) {
          case ComplianceTypes.DBS.toLowerCase():
          case ComplianceTypes.BasicDBS.toLowerCase():
            await SubmitDBSComplianceData(complianceTypeSubmitData);
            break;
          default:
            break;
        }
      }
    }
    SubmitComplianceTypeDataRequest();
  }, [complianceTypeSubmitData]); 

  const SubmitFeedback = async (feedback: IFeedbackRating) => {
    useAPIRequest(Constants.API_URLS.POST_FEEDBACK_RATING, {
      method: "POST",
      body: feedback
    })
  }

  useEffect(() => {
    async function SubmitFeedbackRequest() {
      if (feedback) {
          await SubmitFeedback(feedback);
      }
    }
    SubmitFeedbackRequest();
  }, [feedback]); 

  useEffect(() => {
    async function SendRefreshRequest() {
      if (callRefresh) {
        await RefreshComplianceProcessData();
      }
    }
    SendRefreshRequest();
  }, [callRefresh])

  const RefreshComplianceProcessData = () => {
    useAPIRequest(Constants.API_URLS.GET_WORKER_ROLE_COMPLIANCE + checkId, {
      method: "GET",
      body: ""
    }).then((res) => {
      setComplianceProcessData(res!);
      setCallRefresh(false);
      setLoader(false);
    }).catch(() => {
      setLoader(false);
    })
  }

  const handleNextStep = (updatedProcessData?: IComplianceProcess, editor?: string) => {
    if (updatedProcessData) {
      if (complianceProcessData) {
        
        if (!complianceProcessData.processData) {
          complianceProcessData.processData = {};
        }
        var workerProfile: IProfile = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA);
        if (!workerProfile) {
          workerProfile = {};
        }
        switch (editor!.toLowerCase().trim()) {
          case Constants.COMPLIANCE_STEPS_EDITORS.INTRO.toLowerCase():
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO.toLowerCase():
            complianceProcessData.processData.personal = updatedProcessData.processData.personal;
            workerProfile.personal = updatedProcessData.processData.personal;
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.ADDRESS_INFO.toLowerCase():
            complianceProcessData.processData.addresses = updatedProcessData.processData.addresses;
            workerProfile.addresses = updatedProcessData.processData.addresses;
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.CONVICTIONS.toLowerCase():
            complianceProcessData.processData.convictions = updatedProcessData.processData.convictions;
            workerProfile.convictions = updatedProcessData.processData.convictions;
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.WORK_ABROAD.toLowerCase():
            complianceProcessData.processData.other = updatedProcessData.processData.other;
            workerProfile.other = updatedProcessData.processData.other;
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON.toLowerCase():

            if (complianceProcessData.digitalIdentity && 
              complianceProcessData.digitalIdentity.importStatus === ImportStatus.Imported) {
              complianceProcessData.processData.documents = updatedProcessData.processData.documents?.map((d: IDocumentDefinition) => {
                const currentDate = new Date();
                const formattedDate = currentDate.toLocaleDateString().replace(/\//g, '-');
                return {
                  ...d,
                  filename: formattedDate,
                  fileType: d.mimeType,
                  binary: d.mediaContent 
                }
              });

            } else {
              complianceProcessData.processData.documents = updatedProcessData.processData.documents;
              workerProfile.documents = updatedProcessData.processData.documents?.map((d: IDocumentDefinition) => ({...d, binary: null}));
            }
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.DBS_TERMS.toLowerCase():
            complianceProcessData.dbsTerms = updatedProcessData.dbsTerms;
            //Submit Compliance Process Data for Compliance Type
            if (complianceProcessData) {
              setComplianceTypeSubmitData(complianceProcessData);
            }
            break;
          default:
            break;
        }
       
          LocalStorageService.setData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA, workerProfile);
        
        
      }
    }
    if(!(editor && editor!.toLocaleLowerCase().trim() == Constants.COMPLIANCE_STEPS_EDITORS.DBS_TERMS.toLowerCase()))
    {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    
  };

  const loadStepContainer = (editor: string) => {
    switch(editor) {
      case Constants.COMPLIANCE_STEPS_EDITORS.INTRO:
        return <CheckIntro content={dbsUIContent.intro} />
      case Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO:
        return <PersonalInformation uiContent={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO})]} complianceProcessData={complianceProcessData!} isProfileView={true} isCheckDataEditable={isCheckEditable} handleSubmit={handleNextStep} errorHandler={handleGenericError} />
      case Constants.COMPLIANCE_STEPS_EDITORS.ADDRESS_INFO:
        // Create a custom version of the address info step with updated content
        const addressStepIndex = dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { 
          return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.ADDRESS_INFO
        });
        
        // Clone the step to avoid modifying the original
        const customAddressStep = { ...dbsUIContent.steps[addressStepIndex] };
        
        // Override the content with our new guidance text
        customAddressStep.content = "Please provide your address history for the past five years. If you have lived at an address for more than 4 weeks, it must be included. Ensure you provide your full address details, including where relevant: flat number, building name or number, street name, town/city, county and post code.";
        
        return <AddressHistory 
          uiContent={customAddressStep} 
          complianceProcessData={complianceProcessData!} 
          isProfileView={true} 
          isCheckDataEditable={isCheckEditable} 
          handleSubmit={handleNextStep} 
          errorHandler={handleGenericError} 
        />
      case Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON:
        return <VerifyIdentity uiContent={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON})]} complianceProcessData={complianceProcessData!} isCheckDataEditable={isCheckEditable} handleSubmit={handleNextStep} errorHandler={handleGenericError} requestRefresh={requestRefresh} />
      // case Constants.COMPLIANCE_STEPS_EDITORS.OUTRO:
      //   return <CheckOutro content={dbsUIContent.outro} complianceProcessData={complianceProcessData!} handleSubmit={handleSubmitRequest} isCheckDataEditable={isCheckEditable} />
      case Constants.COMPLIANCE_STEPS_EDITORS.CONVICTIONS:
        return <Convictions content={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.CONVICTIONS})]} complianceProcessData={complianceProcessData!} isCheckDataEditable={isCheckEditable} handleSubmit={handleNextStep} errorHandler={handleGenericError} />
      case Constants.COMPLIANCE_STEPS_EDITORS.WORK_ABROAD:
        return <WorkedAbroad content={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.WORK_ABROAD})]} complianceProcessData={complianceProcessData!} isCheckDataEditable={isCheckEditable} handleSubmit={handleNextStep} errorHandler={handleGenericError} />  
      case Constants.COMPLIANCE_STEPS_EDITORS.DBS_TERMS:
        return <DBSTermsAndConditions content={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.DBS_TERMS})]} complianceProcessData={complianceProcessData!} isCheckDataEditable={isCheckEditable} handleSubmit={handleNextStep} errorHandler={handleGenericError} isSubmissionComplete={isSubmissionComplete} />  
      default:
        return <></>
    }
  }

  const onCloseConfirmImportModal = () => {
    setConfirmImportModalOpen(false);
    setCallRefresh(true);
  }

  const requestRefresh = () => {
    setCallRefresh(true);
  }

  const handleGenericError = (message?: string) => {
    setShowError(true);
    setErrorMessage(message!);
  }

  return (
    (loader) ? 
      <GenericLoader />
    : 
    (complianceProcessData) ? 
      <div id="dbs-comp-wrapper" className="main-wrapper">
          <div className="header">
            <div className="title">{Constants.COMPLIANCE_TYPES_DISPLAY_VALUE.DBS} {"  "}
            </div>
          </div>
          <div className="compliance-container" style={{flexDirection: (isMobileOnly) ? "column" : "inherit" }}>
            <MobileView>
              <div className="container-content">
                <Box>
                  <Stepper key={activeStep} activeStep={activeStep} orientation="horizontal">
                  {dbsUIContent.steps.map((step) => (
                    <Step key={step.title}>
                      <StepLabel key={activeStep} className="step-label">
                        {""}
                      </StepLabel>
                    </Step>
                  ))}
                  </Stepper>
                </Box>
              </div>
            </MobileView>
            <div className="container-content">
              {complianceProcessData.status === ProcessStatus.WaitingForWorker && complianceProcessData.changesRequested ?
                <div className="complaince-change-request">
                  {_.map(complianceProcessData.changesRequested, (changeRequest: InfoTypes.ChangeRequest) => (
                    !(changeRequest.completeDate) ? 
                    <>
                    <div className="changes-requested-text">
                      <div dangerouslySetInnerHTML={{__html: changeRequest.message.replace(/(<? *script)/gi, 'illegalscript')}} >
                      </div>
                    </div>
                    </>
                    : ""
                  ))}
              </div> : ""}
              <div className="container-content-dtls">
                {(activeStep !== 0) ? 
                <IconButton color="inherit" className={classes.disableRipple}
                  disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}
                  onClick={() => handleBackStep()}>
                  <ArrowBackIcon fontSize="large" />
                </IconButton> : "" }
                <div className="container-content-form" style={{paddingLeft: (activeStep === 0) ? "58px" : "0px", paddingRight: (activeStep === dbsUIContent.steps.length - 1) ? "58px" : "0px"}}>
                  {loadStepContainer(dbsUIContent.steps[activeStep].editor!)}
                </div>
                {complianceProcessData &&
                  ((complianceProcessData.status === ProcessStatus.WaitingForWorker && activeStep === 0) ||
                  (complianceProcessData.status !== ProcessStatus.WaitingForWorker && activeStep !== dbsUIContent.steps.length - 2)) ? 
                <IconButton color="inherit" className={classes.disableRipple}
                  disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}
                  onClick={() => handleNextStep()}>
                  <ArrowForwardIcon fontSize="large" />
                </IconButton> : "" }
              </div>
            </div>
            <div className="oneFourthWidth">
              <div className="centralize-content">
                <BrowserView>
                  {dbsUIContent && dbsUIContent.id !== "" && dbsUIContent.steps && dbsUIContent.steps.length > 0 ?
                  <Stepper key={activeStep} activeStep={activeStep} orientation="vertical" className="stepper-content">
                    {dbsUIContent.steps.map((step) => (
                      <Step key={step.title}>
                        <StepLabel className="step-label">
                          {step.title}
                        </StepLabel>
                        <StepContent>
                          <div className="step-label-desc" key={activeStep}>
                            {/* <div dangerouslySetInnerHTML={{__html: step.detail.replace(/(<? *script)/gi, 'illegalscript')}} >
                            </div> */}
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  : ""}
                </BrowserView>
              </div>
            </div>
          </div>
          <ImportDigitalIdentityData 
            digitalIdentityData={digitalIDImportableData!} 
            confirmImportModalOpen={confirmImportModalOpen!} 
            complianceId={complianceProcessData!.id} 
            onCloseConfirmImportModal={onCloseConfirmImportModal}
            handleNextStep={handleNextStep} 
          />
          {isSubmissionComplete ? 
            <GenericAlert
              showButtons={true}
              showSecondaryBtn={false}
              showSuccessIcon={!isSubmissionError}
              redAlertIcon={isSubmissionError}
              error={isSubmissionError}
              primaryButtonText={t("common.ok")}
              onCloseModal={() => {
                setSubmissionComplete(false);
                if (!isSubmissionError) {
                  setCallRefresh(true);
                  setLoader(true);
                }
              }}
              onPrimaryButtonClicked={() => {
                setSubmissionComplete(false);
                if (!isSubmissionError) {
                  history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
                }
                setComplianceTypeSubmitData(undefined);
                setSubmissionError(false);
              }}
              open={isSubmissionComplete}
              modalHeight={"360px"}
              modalWidth="620px"
              modalMargin="200px auto"
            >
              <div className="modal-input">
              
                {isSubmissionError ? 
                  <div dangerouslySetInnerHTML={{__html: (errorMessage && errorMessage !== "") ?  errorMessage.replace(/(<? *script)/gi, 'illegalscript') : t("errors.error_msg")}} >
                  </div>
                : t("dbs_compliance.success_msg")}
              </div>
            </GenericAlert>
          : ""}
          {showError ? 
            <GenericAlert
            showButtons={true}
            showSecondaryBtn={false}
            error={true}
            primaryButtonText={t("common.ok")}
            onCloseModal={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            onPrimaryButtonClicked={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            open={showError}
            modalHeight={"350px"}
            modalWidth="620px"
            modalMargin="200px auto"
          >
            <div className="modal-input">
                <div dangerouslySetInnerHTML={{__html: (errorMessage && errorMessage !== "") ?  errorMessage.replace(/(<? *script)/gi, 'illegalscript') : t("errors.error_msg")}} >
                </div>
            </div>
          </GenericAlert>
          : ""}
      </div>
        : <div>Data Not Fetched </div>
  )
}
    